import { useQuery, useMutation } from "urql";

const GETAGENDAS = `query GET_AGENDAS(
    $input: AgendaSearchInput!
    $pagination: PaginationInput!
    $search: String
  ) {
    paginatedSearchAgenda(
      input: $input
      pagination: $pagination
      search: $search
    ) {
      totalDocs
      docs {
        id
        name
        rut
        email
        address
        zone
        activity
        isClient
        isProvider
        bankRut
        bankId
        bankAccount
        bankAccountType
        paymentDays
        defaultCashflowArea
        paymentDaysClient
        paymentDaysProvider
        defaultCashflowAreaClient
        defaultCashflowAreaProvider
        defaultEERRAreaClient
        defaultEERRAreaProvider
        updatedAt
        worldWide
        nominasLabel
        labels
      }
    }
  }`;

export const GetAgendas = ({ input, pagination, search }) => {
  const [result, reexecuteQuery] = useQuery({
    query: GETAGENDAS,
    variables: { input, pagination, search },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const ALL_AGENDA = `query ALL_AGENDA(
  $companyId: String!
) {
  allAgendas(companyId: $companyId) {
      id
      name
      rut
      isClient
      isProvider
  }
}`;

export const GetAllAgendas = ({ companyId }) => {
  const [result, reexecuteQuery] = useQuery({
    query: ALL_AGENDA,
    variables: { companyId },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const DELETEAGENDA = `mutation DELETE_AGENDA($id: String!) {
  deleteAgenda(id: $id) {
    id
  }
}`;

export const Delete_Agenda = () => {
  const [result, executeMutation] = useMutation(DELETEAGENDA);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const CREATEAGENDA = `mutation CREATE_AGENDA($input: AgendaInput!) {
  createAgenda(input: $input) {
    id
  }
}`;

export const CreateAgenda = () => {
  const [result, executeMutation] = useMutation(CREATEAGENDA);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const UPDATEAGENDA = `mutation UPDATE_AGENDA($id: String!, $input: UpdateAgendaInput!) {
  updateAgenda(id: $id, input: $input) {
    id
  }
}`;

export const UpdateAgenda = () => {
  const [result, executeMutation] = useMutation(UPDATEAGENDA);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const UPDATE_AGENDA_FIELDS = `mutation UPDATE_AGENDA_FIELDS($id: String!, $input: ContactInfoInput!, $email: String!) {
  updateAgendaFields(id: $id, input: $input, email: $email) {
    id
  }
}`;

export const UpdateContactAgenda = () => {
  const [result, executeMutation] = useMutation(UPDATE_AGENDA_FIELDS);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const BULK_AGENDA = `mutation CREATE_BULK_AGENDAS($input: BulkAgendaInput!) {
  createBulkAgendas(input: $input) {
    Upserted {
      id
    }
    duplicados {
      id
      rut
      isClient
      isProvider
      name
      email
    }
  }
}
`;

export const BulkAgenda = () => {
  const [result, executeMutation] = useMutation(BULK_AGENDA);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const DOWNLOAD_AGENDA = `mutation DOWNLOAD_AGENDA($companyId: String!, $isTemplate: Boolean) {
    generateAgendaExcel(companyId: $companyId, isTemplate: $isTemplate)
  }
`;

export const DownloadAgenda = () => {
  const [result, executeMutation] = useMutation(DOWNLOAD_AGENDA);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const AGENDA_BY_RUT = `query AgendaByRut(
  $rut: String!,
  $companyId: String!,
  $worldWide: String!
) {
  AgendaByRut(
    rut: $rut,
    companyId: $companyId,
    worldWide: $worldWide
  ) {
      id
      name
      rut
      email
      address
      zone
      activity
      isClient
      isProvider
      bankRut
      bankId
      bankAccount
      bankAccountType
      paymentDays
      defaultCashflowArea
      paymentDaysClient
      paymentDaysProvider
      defaultCashflowAreaClient
      defaultCashflowAreaProvider
      defaultEERRAreaClient
      defaultEERRAreaProvider
      updatedAt
      worldWide
      nominasLabel
      labels
  }
}`;

export const AgendaByRut = (rut, companyId, worldWide) => {
  const [result, reexecuteQuery] = useQuery({
    query: AGENDA_BY_RUT,
    variables: { rut, companyId, worldWide },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

export const AGENDA_BY_NAME = `
  query AgendaByName(
    $name: String!,
    $companyId: String!
  ) {
    AgendaByName(
      name: $name,
      companyId: $companyId
    ) {
        id
        name
        rut
        defaultCashflowAreaProvider
        isClient
        isProvider
    }
  }
`;

export const AgendaByName = (name, companyId) => {
  const [result, reexecuteQuery] = useQuery({
    query: AGENDA_BY_NAME,
    variables: { name, companyId},
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const FIND_AGENDA = `query FIND_AGENDA(
  $id: String!,
) {
  Agenda(
    id: $id,
  ) {
      id
      name
      rut
      email
      address
      zone
      activity
      isClient
      isProvider
      bankRut
      bankId
      bankAccount
      bankAccountType
      paymentDays
      defaultCashflowArea
      paymentDaysClient
      paymentDaysProvider
      defaultCashflowAreaClient
      defaultCashflowAreaProvider
      defaultEERRAreaClient
      defaultEERRAreaProvider
      defaultEOAFAreaClient
      defaultEOAFAreaProvider
      defaultCostCenterClient
      defaultCostCenterProvider
      updatedAt
      worldWide
      nominasLabel
      outsideProviderId
      labels
  }
}`;

export const FindAgenda = (id) => {
  const [result, reexecuteQuery] = useQuery({
    query: FIND_AGENDA,
    variables: { id },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};